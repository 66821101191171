.ui.label {
  &.language {
    padding: @languageVerticalPadding @languageHorizontalPadding;
    color: @green;
    font-weight: @languageFontWeight;
    margin-right: @languageMarginRight;
    position: relative;
    bottom: @languageLift;
  }
  &.active-filters-count-label {
    background-color: @accentColor;
    color: @white;
  }

  .ui.tree-field .actions & {
    height: min-content;
    margin: 0.5rem 0;
  }
}
